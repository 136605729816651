import moment from "moment";
import 'popper.js';
import 'bootstrap-datepicker';
import 'bootstrap';

export class HomeOffice {

    /**
     * @constructor
     * @param {moment} today 
     * @returns void|null
     */
    constructor(today) {
        // set timezone
        moment.tz.setDefault('Europe/Berlin');
        /**
         * @constant {Element} homeOfficeCalendar
         * the calendar container
         */
        const homeOfficeCalendar = document.querySelector('.schedule-calendar.home-office');
        // if calendar not exists cancel the process
        if(homeOfficeCalendar === null) {
            return;
        }
        /**
         * @property {Element} calendar
         * the calendar container
         * available for the `HomeOffice` instance
         */
        this.calendar = homeOfficeCalendar;
        /**
         * @property {String|null} homeOfficeManagerId
         * the id of the job family manager
         */
        this.homeOfficeManagerId = homeOfficeCalendar.dataset.hoManager ?? null;
        /**
         * @property {Boolean} isHr
         * if the user is human resources manager then is `true`
         * otherwise is `false`
         */
        this.isHr = homeOfficeCalendar.dataset.hr ?? false;
        /** @property {Number|Boolean} hasRepresentative */
        this.hasRepresentative = homeOfficeCalendar.dataset.hasRepresentative ?? false;

        const representationsData = homeOfficeCalendar.dataset.representations ?? null;
        this.representations = representationsData !== null ? JSON.parse(representationsData) : null;

        this.preloadTimer = null;
        this.today = today;
        this.month = today;
        this.minDate = this.today.hour() >= 16 ? moment(this.today).add(1, 'day').hour(16) : moment();
        this.maxDate = this.today.hour() >= 16 ? moment(this.today).add(1, 'day').add(4, 'week').hour(16) : moment(this.today).add(4, 'week');

        this.employeeIds = [];

        this.getEmployees(this.homeOfficeManagerId, this.representations, this.isHr);
        this.getCalendarHeader();

    }

    /**
     * @method getCalendarHeader
     */
    getCalendarHeader () {
        const calendarHeader = document.querySelector('.calendar-header');
        const dateFilterHtml = '<strong class="month-title btn btn-outline-primary btn-sm ml-2 mr-3" data-month="' + moment(this.month).format('YYYY-MM-01') + '">' + moment(this.month).format('MMMM YYYY') + '</strong>';
        const jobFamilyFilter = this.isHr ? `<div class="dropdown d-inline-block ml-2 mr-3"><strong class="jobfamily-filter d-none btn btn-outline-primary btn-sm" data-toggle="dropdown"><i class="fal fa-filter"></i> Job family</strong></div>` : '';
        const representativeLink = this.homeOfficeManagerId !== null && this.representations === null ? (this.hasRepresentative === false ? '<a href="/employee/account/representatives" class="btn btn-outline-primary btn-sm">Representative</a>' : '') : '';
        let headerHtml = '<div class="row justify-content-between"><div class="col-auto header-filter">' + dateFilterHtml + jobFamilyFilter + '</div><div class="col-auto mr-3">' + representativeLink + '</div></div><div class="days d-flex justify-content-start">';
        this.getDaysArray(this.month.year(), this.month.month()).forEach(day => {
            const dayDate = moment(day[2], 'DD.MM.YYYY').startOf('day'),
                isWeekend = dayDate.format('dddd') === 'Sunday' || dayDate.format('dddd') === 'Saturday',
                weekendCls = isWeekend ? ' weekend' : '',
                disabled = dayDate.diff(this.minDate.startOf('day'), 'days') <= 0 || dayDate.diff(this.maxDate.startOf('day'), 'days') > 0 ? true : false,
                opacity = disabled && weekendCls === '' ? 0.5 : 1;
            headerHtml += `<div class="day d-flex align-items-center justify-content-center${weekendCls}" style="opacity:${opacity};" data-day="${day[0]}"><span>${day[0]}</span></div>`;
        });
        headerHtml += '</div>';

        calendarHeader.innerHTML = headerHtml;
        this.isHr ? this.getExportBtn(this.today) : null;
        this.handleMonthChange();
    }

    /**
     * @method getEmployees
     * @param {String|null} manager 
     * @param {Array|null} representations
     * @param {Boolean} isHr 
     */
    getEmployees (manager = null, representations = null, isHr = false) {

        const self = this;

        this.callFetch(
            '/employee/calendar-homeoffice/get-employees', 
            {manager: manager, representations: representations, isHr: isHr}
        ).then((response) => {

            if(response.ok === false) {
                throw new Error(response.statusText);
            }

            return response.json();
        }).then((response) => {
            const employeeWrapper = this.calendar.querySelector('.employee-wrapper');
            const employeeCalendarWrapper = this.calendar.querySelector('.calendar');
            employeeWrapper.classList.add('invisible');
            employeeCalendarWrapper.classList.add('invisible');

            employeeWrapper.querySelectorAll('.employee').forEach(item => item.remove());
            employeeCalendarWrapper.innerHTML = '';

            let jobFamily = '';
            let jobFamilies = [];
            Object.entries(response).forEach(employee => {
                const item = employee[1] ?? employee;
                const employeeElem = document.createElement('div');
                let classes = [];
                this.employeeIds.push(item.id);
                if(parseInt(this.homeOfficeManagerId) === parseInt(item.manager) || this.representations?.includes(parseInt(item.manager))) {
                    classes.push('user-is-manager');
                }

                if(jobFamily !== (item.jobfamily ?? 'Not defined')) {
                    jobFamily = item.jobfamily ?? 'Not defined';
                    jobFamilies.push(item.jobfamily ?? 'Not defined');
                    const jobFamilyElem = document.createElement('div');
                    jobFamilyElem.classList.add('employee', 'align-items-center', 'is-jobfamily', ...classes);
                    jobFamilyElem.innerHTML = `<p><small class="text-muted">${jobFamily}${classes.includes('user-is-manager') ? '<small class="d-block" style="margin-top:-5px;">Your team</small>' : ''}</small></p>`;
                    jobFamilyElem.dataset.jobfamily = jobFamily;
                    employeeWrapper.append(jobFamilyElem);
                }

                employeeElem.classList.add('employee', 'align-items-center', ...classes);
                employeeElem.dataset.id = item.id;
                employeeElem.dataset.jobfamily = item.jobfamily ?? 'Not defined';
                employeeElem.innerHTML = `<p><span class='text-uppercase'>${item.lastName}</span> ${item.firstName}</p>`
                employeeWrapper.append(employeeElem);

                
            });

            this.getHomeOfficeByEmployees(this.employeeIds);
            return jobFamilies;

        }).then(jobFamilies => {

            if(this.isHr) {
                const jobFamilyDropdown = this.getJobFamilyDropdown(jobFamilies);
                const jobFamilyFilter = this.calendar.querySelector('.calendar-header .jobfamily-filter');
                if(jobFamilyDropdown instanceof Element) {
                    jobFamilyFilter.insertAdjacentElement('afterend', jobFamilyDropdown);
                    jobFamilyFilter.classList.remove('d-none');
                } else {
                    jobFamilyFilter.classList.add('d-none');
                }
            }
            setTimeout(()=>{
                this.calendar.querySelector('.calendar').classList.remove('invisible');
                this.calendar.querySelector('.employee-wrapper').classList.remove('invisible');
                this.removePreloader();
            }, 250);
        })        
        .catch((error) => {
            this.removePreloader();
            console.error(error);
        });

    }

    /**
     * @method getJobFamilyDropdown
     * @param {Array} jobFamilies 
     * @returns 
     */
    getJobFamilyDropdown(jobFamilies)
    {
        if(jobFamilies.length <= 1) {
            return;
        }
        const self = this;
        const dropdown = document.createElement('div');
        dropdown.classList.add('dropdown-menu');

        jobFamilies.forEach(jobfamily => {
            const dropdownItem = document.createElement('span');
            dropdownItem.classList.add('dropdown-item', 'jobfamily-filter-item');
            dropdownItem.textContent = jobfamily;
            dropdown.append(dropdownItem);

            dropdownItem.addEventListener('click', function(e) {
                self.resetFilter(dropdown);
                const filteredElements = self.calendar.querySelectorAll('.employee:not([data-jobfamily="' + dropdownItem.textContent + '"]), .employee-calendar:not([data-jobfamily="' + dropdownItem.textContent + '"])');
                filteredElements.forEach(elem => elem.classList.add('d-none'));
                dropdown.previousElementSibling?.classList.add('btn-primary');
                dropdownItem.classList.add('active');

                if(dropdown.querySelector('.filter-reset') !== null) {
                    return;
                }
                
                const resetItem = document.createElement('span');
                resetItem.classList.add('dropdown-item', 'filter-reset');
                resetItem.innerHTML = '<i class="fal fa-times mr-2"></i><i>Reset filter</i>';

                dropdown.prepend(resetItem);
                resetItem.insertAdjacentHTML('afterend', '<div class="dropdown-divider"></div>');

                resetItem.addEventListener('click', function(e) {
                    self.resetFilter(dropdown);
                    resetItem.remove();
                    dropdown.querySelector('.dropdown-divider').remove();
                    dropdown.previousElementSibling?.classList.remove('btn-primary');
                });
            });
        });

        return dropdown;

    }

    /**
     * @method resetFilter
     * @param {Element} dropdown 
     */
    resetFilter(dropdown) {
        this.calendar.querySelectorAll('.employee, .employee-calendar').forEach(elem => elem.classList.remove('d-none'));
        dropdown?.querySelectorAll('.active').forEach(item => item.classList.remove('active'));
    }

    /**
     * @method getHomeOfficeByEmployee
     * @returns {String}
     */
    async getHomeOfficeByEmployees ()
    {
        const self = this;

        return await this.callFetch(
            '/employee/calendar-homeoffice/get-by-employees',
            {employees: this.employeeIds, month: this.month.format('YYYY-MM-DD')}
        ).then(response => {
            if(response.ok === false) {
                throw new Error(response.statusText);
            }
            return response.json();
        }).then(response => {

            const employeeCalendarWrapper = this.calendar.querySelector('.calendar');

            let html = '';
            let jobFamily = null;

            response.homeOffices.forEach(item => {

                let classes = '';
                const manager = item.jobFamily?.manager?.id ?? 0;
                if(parseInt(this.homeOfficeManagerId) === parseInt(manager) || this.representations?.includes(parseInt(manager))) {
                    classes += ' user-is-manager';
                }

                if(jobFamily !== (item.jobFamily?.title ?? 'Not defined')) {
                    jobFamily = item.jobFamily?.title ?? 'Not defined';
                    html += `<div class="employee-calendar${classes}" style="height:40px;" data-jobfamily="${jobFamily}"></div>`;
                }

                const location = this.getLocation(item.location, item.primaryLocation);
                const holidays = [];
                location.holidays.forEach(holiday => {
                    const holidayDate = new Date(holiday['holidayDate']['date']);
                    holidays[holidayDate.getDate() + '.' + (holidayDate.getMonth() + 1) + '.' + holidayDate.getFullYear()] = holiday;
                });
                html += `<div class="employee-calendar${classes}" data-id="${item.id}" data-jobfamily="${jobFamily}">`;
                html += '<div class="employee-days d-flex justify-content-start" data-ho-per-week="' + location?.hoDaysPerWeek + '">';
                this.getDaysArray(this.month.year(), this.month.month()).forEach(day => {
                    let bgColor = '#fff',
                        icon = '',
                        tooltipCls = '',
                        commentTxt = '',
                        tooltip = '',
                        thisDate = moment(day[2], 'DD.MM.YYYY').startOf('day'),
                        thisDay = thisDate.day(),
                        todayCls = thisDate.diff(moment().startOf('day'), 'days') === 0 ? ' today' : '',
                        weekendCls = thisDay === 0 || thisDay === 6 ? ' weekend' : '',
                        disabled = thisDate.diff(this.minDate.startOf('day'), 'days') <= 0 || thisDate.diff(this.maxDate.startOf('day'), 'days') > 0 ? true : false,
                        opacity = 1,
                        id = '',
                        attendanceId = '',
                        employeeId = '',
                        hoClass = '',
                        isHoliday = false,
                        dataAttributes = '',
                        disabledCls = '';
    
                    if(disabled) {
                        opacity = 0.5 ;
                        bgColor = '#ddd';
                        disabledCls = ' readonly';
                    }
    
                    if(holidays[thisDate.format('D.M.YYYY')]) {
                        isHoliday = true;
                        bgColor = "#f5f5f5";
                        icon = "<i class='fal fa-star'></i>";
                        tooltipCls = ' is-holiday';
                        tooltip = " date-comment='" + holidays[thisDate.format('DD.MM.YYYY')]?.name + "'";
                    }
    
                    item.homeOffices.forEach(ho => {
                        if(thisDate.diff(moment(ho.date.date), 'days') === 0) {
                            const status = this.getHomeOfficeStatus(ho.status);
                            bgColor = status.bgColor;
                            icon = '<i class="' + status.icon + '"></i>';
                            hoClass = ' has-home-office';
                            dataAttributes = ` data-id="${ho.id}" data-employee="${ho.employeeName}" data-status="${ho.status ?? ''}" data-status="${item.statusDate?.date ?? ''}"`;
                        }
                    });
    
                    html += `<div class="day d-flex align-items-center justify-content-center grey${todayCls}${weekendCls}${hoClass}${disabledCls}" style="background-color: ${bgColor};opacity:${opacity}" data-manager="${manager}" data-date="${thisDate.format('DD.MM.YYYY')}"${dataAttributes}>${icon}</div>`;
                });

                html += '</div></div>';
            });

            employeeCalendarWrapper.innerHTML = html;

            employeeCalendarWrapper.querySelectorAll('.day.has-home-office').forEach(hoDay => {
                hoDay.addEventListener('click', function(e) {
                    self.getManagerDialog(hoDay).then(dialog => {
                        self.getModalTemplate('body', dialog, moment(hoDay.dataset.date, 'DD.MM.YYYY'));
                        $('#home-office-modal').modal({
                            show: true,
                            backdrop: 'static'
                        });

                        document.querySelector('#home-office-modal .btn.accept')?.addEventListener('click', function(e) {
                            self.onAccept(e, hoDay.dataset.id, (hoDay.dataset.manager ?? 0));
                        });

                        document.querySelector('#home-office-modal .btn.reject')?.addEventListener('click', function(e) {
                            self.onReject(e, hoDay.dataset.id, (hoDay.dataset.manager ?? 0));
                        });

                        $('#home-office-modal').on('hidden.bs.modal', function(e) {
                            $(this).remove();
                        });
                    });
                    
                });
            });
        }).catch(error => {
            console.error(error);
        });

    }

    /**
     * @method callFetch
     * @param {String} url 
     * @param {Object} [data] 
     * @param {String} [method] 
     * @returns 
     */
    async callFetch (url, data = {}, method = 'POST') {

        this.preloadTimer !== null ? this.addPreloader() : null;
        return await fetch(url, {
            method: method,
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }


    addPreloader () {
        this.preloadTimer = setTimeout(function() {
            const preloader = document.createElement('div');
            preloader.classList.add('preloader');
            preloader.innerHTML = '<span class="content"><i class="fas fa-spinner-third"></i></span>';
            document.body.append(preloader);
        }, 250);
    }

    removePreloader() {
        clearTimeout(this.preloadTimer);
        document.querySelector('.preloader')?.remove();
    }

    getDaysArray (year, month) {
        const names = [ 'sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat' ];
        let date = new Date(year, month, 1),
            result = [];
        while (date.getMonth() === month) {
            result.push([date.getDate(), names[date.getDay()], moment(date).format('DD.MM.YYYY')]);
            date.setDate(date.getDate() + 1);
        }
        return result;
    }

    getHomeOfficeStatus(status) {
        let responseStatus = {};
        switch (status) {
            case 0: 
                responseStatus.icon = 'fal fa-times';
                responseStatus.bgColor = '#a7c4de';
                responseStatus.text = 'rejected';
                break;
            case 1:
                responseStatus.icon = 'fal fa-house-user';
                responseStatus.bgColor = '#fbbf4c';
                responseStatus.text = 'accepted';
                break;
            case 2:
                responseStatus.icon = 'fal fa-comment-slash';
                responseStatus.bgColor = '#a7c4de';
                responseStatus.text = 'unanswered';
                break;
            default:
                responseStatus.icon = 'fal fa-question';
                responseStatus.bgColor = '#fbbf4c';
                responseStatus.text = 'requested';

        }

        return responseStatus;
    }


    handleMonthChange() {

        const monthElement = this.calendar.querySelector('.month-title');
        const self = this;
        if(monthElement === null) {
            return;
        }

        monthElement.addEventListener('click', function(e) {
            const month = monthElement.dataset.month;
            $('.home-office .month-title').datepicker({
                startView: 1,
                defaultViewDate: moment(month, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                minViewMode: 1,
                maxViewMode: 1,
                format: 'dd.mm.yyyy',
                autoclose: true,
                todayBtn: false,
                todayHighlight: true,
                disableTouchKeyboard: true,
                weekStart: 1,
                zIndexOffset: 2000
            }).on('changeMonth', function(e){
                const date = moment(e.date);
                self.month = date;
                self.getCalendarHeader();
                self.getEmployees(self.homeOfficeManagerId, self.representations, self.isHr);
                // if($header.hasClass('attendance-export')) {
                //     changeExportURL();
                // }
            });
        });

    }

    async getManagerDialog (homeOfficeData) {
        const id = homeOfficeData.dataset.id ?? null;
        const disbaled = homeOfficeData.classList.contains('readonly');
        const self = this;
        let html;
        return await this.callFetch('/employee/calendar-homeoffice/get-by-id', {id: id}, 'POST')
            .then(response => {
                if(response.ok === false) {
                    throw new Error(response.statusText);
                }

                return response.json();
            }).then(response => {
                const homeOffice = response;
                const homeOfficeStatus = self.getHomeOfficeStatus(homeOffice.status);

                html = `<div class="">Requested by <b>${homeOffice.employeeName}</b><br>
                Status <b>${homeOfficeStatus.text}</b>${homeOffice?.statusDate !== null ? ' <small class="badge outlined ml-2"><i class="fal fa-calendar"></i> ' + moment(homeOffice.statusDate?.date).format('DD.MM.YYYY HH:mm:ss') + '</small>' : ''}<br>
                Manager <b>${homeOffice.employeeLeaderName !== null ? homeOffice.employeeLeaderName : 'not set'}</b></div>`;
                
                if(disbaled === false && 
                    (
                        parseInt(this.homeOfficeManagerId) === parseInt(homeOffice.employeeLeaderId?.id) ||
                        this.representations?.includes(homeOffice.employeeLeaderId?.id)
                    ) 
                ) {
                    html += `<div class="mt-2"><hr class='mb-3 mt-0'>
                    <button class="btn btn-primary accept"><i class="fal fa-check"></i> Accept</button>
                    <button class="btn btn-light reject"><i class="fal fa-times"></i> Reject</button>
                    </div>`;
                }

                return html;
            });
    }


    openModal () {

    }

    getModalTemplate(container = 'body', content = '', date)
    {
        const scope = document.querySelector(container) ?? null;
        if(container === null) {
            return;
        }

        const html = "<div id='home-office-modal' class='modal' tabindex='-1' role='dialog' aria-labelledby='homeOfficeModalTitle' aria-hidden='true'>" +
            "    <div class='modal-dialog modal-md' role='document'>" +
            "        <div class='modal-content'>" +
            "            <div class='modal-header'>" +
            "                <h5 class='modal-title subline' id='homeOffieModalTitle'>Mobile work request</h5>" +
            "                <button type='button' class='close' aria-label='Close'>" +
            "                    <span aria-hidden='true' class='fal fa-times'></span>" +
            "                </button>" +
            "            </div>" +
            "            <div class='modal-body'>" +
            "                <div class='container-fluid px-0'>" +
            "                   <div><hr class='mb-3 mt-0'><i class='fal fa-calendar-alt'></i> <b>" + date.format('DD.MM.YYYY') + "</b>" + content +
            "                   " +
            "                   </div>" +
            "                </div>" +
            "            </div>" +
            "        </div>" +
            "    </div>" +
            "</div>";

        scope.insertAdjacentHTML('beforeend', html);
    }


    onAccept(event, homeOfficeId, manager)
    {

        if(this.homeOfficeManagerId === null && this.representations?.includes(manager) === false) {
            return;
        }
        event.target.disabled = true;
        const self = this;
        this.callFetch('/employee/calendar-homeoffice/accept', {id: homeOfficeId}, 'POST')
            .then(response => {
                if(response.ok !== true) {
                    throw new Error('Accepted failed');
                }
                return response.json();
            }).then(response => {
                event.target.disabled = false;
                if(response.success === true) {
                    $('#home-office-modal').modal('hide');
                    $('#home-office-modal').on('hidden.bs.modal', function(e){
                        $(this).modal('dispose');
                    });

                    const dayElem = self.calendar.querySelector('.day[data-id="' + homeOfficeId + '"]');
                    dayElem.style.setProperty('background-color', '#fbbf4c');
                    dayElem.innerHTML = '<i class="fal fa-house-user"></i>';
                    return;
                }

                const errorElement = self.getErrorElement();
                document.querySelector('#home-office-modal .modal-body .container-fluid').insertAdjacentElement('afterbegin', errorElement);

            });
    }


    onReject(event, homeOfficeId, manager)
    {
        if(this.homeOfficeManagerId === null && this.representations?.includes(manager) === false) {
            return;
        }
        event.target.disabled = true;
        const self = this;
        this.callFetch('/employee/calendar-homeoffice/reject', {id: homeOfficeId}, 'POST')
            .then(response => {
                if(response.ok !== true) {
                    throw new Error('Accepted failed');
                }
                return response.json();
            }).then(response => {
                event.target.disabled = false;
                if(response.success === true) {
                    $('#home-office-modal').modal('hide');
                    $('#home-office-modal').on('hidden.bs.modal', function(e){
                        $(this).modal('dispose');
                    });

                    const dayElem = self.calendar.querySelector('.day[data-id="' + homeOfficeId + '"]');
                    dayElem.style.setProperty('background-color', '#a7c4de');
                    dayElem.innerHTML = '<i class="fal fa-times"></i>';
                    return;
                }

                const errorElement = self.getErrorElement();
                document.querySelector('#home-office-modal .modal-body .container-fluid').insertAdjacentElement('afterbegin', errorElement);
            });
    }


    getErrorElement(msg = null)
    {
        const errorElement = document.createElement('div');
        errorElement.classList.add('alert', 'alert-danger');
        errorElement.innerHTML = msg ?? `<b>Saving faild</b><br>Please try again later.`;

        return errorElement;
    }


    getExportBtn(date)
    {
        if(this.isHr === false) {
            return;
        }
        const self = this;
        const btn = `<div class="dropdown d-inline-block">
            <button class="btn btn-outline-primary btn-sm home-office-export" data-toggle="dropdown"><i class="fal fa-download"></i> Export</button>
            <div class="dropdown-menu">
                <span class="dropdown-item export-duration month">Month: <i>${this.month.format('MMMM YYYY')}</i></span>
                <span class="dropdown-item export-duration year">Year: </i>${this.month.format('YYYY')}</i></span>
            </div>
        </div>`;
        const calendarHeader = document.querySelector('.calendar-header');
        const headerFilterContainer = calendarHeader.querySelector('.header-filter');

        if(headerFilterContainer === null) {
            return;
        }

        headerFilterContainer.insertAdjacentHTML('beforeend', btn);
        headerFilterContainer.querySelectorAll('.export-duration').forEach(exportBtn => {
            exportBtn.addEventListener('click', function() {
                const type = exportBtn.classList.contains('month') ? 'monthly' : 'yearly';
                location.href = '/employee/calendar-homeoffice/export?date=' + self.month.format('DD.MM.YYYY') + '&type=' + type + '&employees=' + JSON.stringify(self.employeeIds);
            });
        });

    }


    getLocation(locations, primaryLocation = null)
    {
        let location = null;
        if(locations.length > 1 && primaryLocation !== null) {
            locations.forEach(loc => {
                if(loc.id !== primaryLocation.id || location !== null) {
                    return;
                }
                location = loc;
            });
        } else {
            location = locations.shift();
            locations.unshift(location);
        }

        return location;
    }


}